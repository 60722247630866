<template>
  <v-row>
    <v-col v-for="{ id, name, filetype } of submissionData" :key="id">
      <file-upload-image v-if="filetype.substring(0, 5) === 'image'" :id="id" :name="name" :filetype="filetype"></file-upload-image>
      <v-list v-else class="pa-0">
        <v-list-item target="_blank" :href="'https://drive.google.com/file/d/' + id + '/view'">
          <v-list-item-avatar>
            <v-icon v-if="['image', 'audio', 'video'].includes(filetype.substring(0, 5))">fal fa-file-{{ filetype.substring(0, 5) }}</v-icon>
            <v-icon v-else-if="filetype === 'application/pdf'">fal fa-file-pdf</v-icon>
            <v-icon v-else-if="filetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">fal fa-file-excel</v-icon>
            <v-icon v-else-if="filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'">fal fa-file-word</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    submissionData: {
      type: Array,
      required: true
    }
  },
  components: {
    FileUploadImage: () => import('./FileUploadImage')
  }
}
</script>
